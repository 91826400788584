import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LayoutContext from '../../context/LayoutContext';
import Collapse, { Panel } from '../../components/Collapse';
import Link from '../Link';

export const CollapseStyled = styled(Collapse)`
  .panel {
    &__header {
      font-size: 20px;
      border-bottom: 0;
      /* &:focus {
        box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
      } */
    }

    &__content {
      padding: 0 !important;
    }
  }

  border-bottom: ${(props) => props.theme.prodcut.border};
`;

const StyledLink = styled(Link)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  display: block;
  color: ${(props) => props.theme.home.subContentColor};
  font-size: 14px;
  background-color: ${(props) => props.theme.home.subContentBg};

  &:not(:last-child) {
    border-bottom: 1px solid #cecece;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const SubCollapse = styled(Collapse)`
  .panel {
    &.active {
      .panel__header {
        background-color: ${(props) => props.theme.home.subCollapseActiveBg};
        color: ${(props) => props.theme.home.subCollapseActiveColor};
      }
      .panel__content {
        a {
          color: ${(props) => props.theme.home.subContentColor};
        }
      }
    }

    &__header {
      padding-left: 40px;
      font-size: 16px;
    }
  }
`;

const Menu = ({ list }) => {
  const { t } = useTranslation();
  const layoutProps = useContext(LayoutContext);
  const { locale, gender } = layoutProps.pageContext;
  const genderPath = gender === 'women' ? '/femme' : '';

  const getLocaleName = (item) => {
    return item.localizations[locale].name;
  };

  const { subType } = useSelector((state) => state.common);
  // console.log(list);

  const types = list.reverse();

  return (
    <>
      <CollapseStyled>
        {types.map((type, index) => {
          return (
            <Panel key={index} panelKey={index} header={getLocaleName(type)}>
              <SubCollapse>
                {subType.map((item, j) => {
                  return (
                    <Panel key={j} panelKey={j} header={t(item.name)}>
                      {type[item.key].map((child, k) => {
                        return (
                          <StyledLink
                            key={k}
                            to={`${genderPath}/category/${type.slug}/${child.slug}`}
                          >
                            {getLocaleName(child)}
                          </StyledLink>
                        );
                      })}
                    </Panel>
                  );
                })}
              </SubCollapse>
            </Panel>
          );
        })}
      </CollapseStyled>
    </>
  );
};

export default Menu;
