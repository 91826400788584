import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Link from '../Link';

const Container = styled.div`
  background-color: ${(props) => props.theme.headerBgColor};
`;

const TabItem = styled(Link)`
  display: block;
  text-align: center;
  padding: 5px 0;
  color: ${(props) => props.theme.navigation.tabColor};
  background-color: ${(props) => props.theme.navigation.tabBg};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.3s;
  text-transform: uppercase;
  cursor: pointer;

  &.active,
  &:hover {
    color: ${(props) => props.theme.navigation.tabActiveColor} !important;
    background-color: ${(props) => props.theme.navigation.tabActiveBg};
    padding: 8px 0;
  }

  &:nth-child(2):hover {
    color: #ffffff;
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.navigation.tabActiveColor};
  }
`;

const Tab = ({ gender }) => {
  const { t } = useTranslation();

  return (
    <Container className="row no-gutters align-items-end">
      <TabItem className={`col-6 ${gender === 'women' ? 'active' : ''}`} to="/femme">
        {t('women_care')}
      </TabItem>
      <TabItem
        className={`col-6 ${gender === 'men' ? 'active' : ''}`}
        to="/"
      >
        {t('mens_care')}
      </TabItem>
    </Container>
  );
};

export default Tab;
